.apexcharts-legend-marker {
  border-width: 1px;
}
body {
  color: #2d3748;
  color-interpolation-filters: white;
}

.MuiSelect-selectMenu {
  white-space: normal !important;
  width: 100% !important;
}

.MuiTabs-flexContainer {
  justify-content: center !important;
}
